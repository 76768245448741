import React from 'react';
import PropTypes from 'prop-types';

const ExtLink = props => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

ExtLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default ExtLink;
