import React from 'react';

import Layout from '../../components/layout/Layout';
import ExtLink from '../../util/ExtLink';
import SocialApe from '../../images/socialapelanding.jpg';
import Firebase from '../../images/firebase.png';

export default () => {
  return (
    <Layout pageTitle="Sociale Ape (demo)">
      <h1 className="text-center green-text">Social Ape (demo)</h1>
      <p className="text-center">
        This was a fun app I wrote to teach people how to combine React,
        Firebase, Redux and Material-UI to create a full social media app (Watch
        me type it on{' '}
        <ExtLink href="https://www.youtube.com/playlist?list=PLMhAeHCz8S38ryyeMiBPPUnFAiWnoPvWP">
          Youtube <i className="fas fa-external-link-alt fa-sm" />
        </ExtLink>
        )
      </p>
      <hr className="horizontal-ruler" />
      <div className="text-center">
        <img src={SocialApe} alt="social ape" />
      </div>
      <p className="text-center">
        <ExtLink href="https://socialape-d081e.firebaseapp.com/">
          Visit demo <i className="fas fa-external-link-alt" />
        </ExtLink>
        {' - '}
        <ExtLink href="https://github.com/hidjou/classsed-react-firebase-functions">
          Server code <i className="fab fa-github" />
        </ExtLink>
        {' - '}
        <ExtLink href="https://github.com/hidjou/classsed-react-firebase-client">
          Client code <i className="fab fa-github" />
        </ExtLink>
        <br />
        (You can login as user@email.com:123456)
      </p>
      <h2 className="green-text">Goal</h2>
      <p>
        As a social media app, Social Ape aims to connect users together by
        allowing them to post their thoughts as 'screams'
      </p>
      <p>
        The app should allow users to interact with each other and do the
        following:
      </p>
      <ul className="bullet-points">
        <li>Register and Login on the website.</li>
        <li>Create and delete posts.</li>
        <li>View, like and comment on posts.</li>
        <li>Create and edit a profile with details and profile image.</li>
        <li>Receive notifications on activity related to one's posts.</li>
        <li>View posts by a user of choice.</li>
      </ul>
      <h2 className="green-text">Method</h2>
      <p>
        With there being many interactions where the user edits the content on
        app, the app had to be designed to be interactive and instantly reflect
        these changes. So it had to be a dynamic SPA.
      </p>
      <p>
        The app needs a database to store user information and posts and all
        related data and meta data.
      </p>
      <p>
        Although using Firebase as a backend might not be the optimal solution
        for this particular app, the idea behind this series is to teach people
        how to use Firebase so it was used as a backend.
      </p>
      <p>
        These are the functionalities and approaches used to implement them:
      </p>
      <ul className="bullet-points">
        <li>
          A REST API for the client app to interface with the backend, using
          Google cloud functions.
        </li>
        <li>
          Database for storing all related data, using Firestore document based
          database.
        </li>
        <li>
          A UI library for styling the app and making it responsive with minimal
          CSS, Matarial-UI (a fantastic React integration of Material Design)
        </li>
        <li>
          A state management library for managing application wide state on the
          React client app, using Redux + react-redux
        </li>
        <li>
          A hosting solution for deploying and hosting the app, using Firebase
          hosting
        </li>
      </ul>
      <h2 className="green-text">Tech</h2>
      <br />
      <ul className="tech-used">
        <li>
          <ExtLink href="https://reactjs.org/">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/245px-React.svg.png"
              alt="react"
              title="React"
            />
          </ExtLink>
          <ExtLink href="https://firebase.google.com/">
            <img src={Firebase} alt="firebase" title="Firebase" />
          </ExtLink>
          <ExtLink href="https://redux.js.org/">
            <img
              src="https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo.png"
              alt="redux"
              title="Redux"
            />
          </ExtLink>
          <ExtLink href="https://material-ui.com/">
            <img
              src="https://material-ui.com/static/images/material-ui-logo.svg"
              alt="Material-UI"
              title="Material-UI"
            />
          </ExtLink>
          <ExtLink href="https://expressjs.com/">
            <img
              src=" https://d2eip9sf3oo6c2.cloudfront.net/tags/images/000/000/359/thumb/expressjslogo.png"
              alt="Express"
              title="Express"
            />
          </ExtLink>
        </li>
      </ul>
    </Layout>
  );
};
